.git {
    /* padding: 44rem 4rem 0 4rem; */
    padding: 65rem 6rem 6rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: all 2s;
    @media(max-width: 769px){
        padding: 105rem 4rem 2rem 4rem;
    }
    @media(max-width: 375px){
        padding-top: 145rem;
    }
    
}
.git-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 769px){
        flex-direction: column;
    }
}
.git-div {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media(max-width: 769px){
        align-items: center;
    }
}
.git-form {
    width: 48.8rem;
    height: 56.8rem;
    border-radius: 3rem;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: #5F666F;
    font-size: 1.4rem;
    font-weight: 400;
    z-index: 200;
    box-shadow: 2px 4px 8px 0px rgba(205, 203, 203, 0.80);
    @media(max-width: 769px){
        width: 34.6rem;
        height: 49.6rem;
        padding-top: 1rem;
    }
}
.git-img-4 {
    align-self: center;
    @media(max-width: 769px){
        width: 4.9569rem;
        height: 4.9569rem;
    }
}
.git-input {
    width: 20.8rem;
    height: 4.8rem;
    border-radius: 10px;
    border: 1px solid #C7C8C9;
    background: #FFF;
    margin: 2rem 2rem 0 0;
    padding-left: 2rem;
    outline: none;
    @media(max-width: 769px){
        width: 15.3rem;
        height: 4rem;
        margin: 1rem 0;
    }
}
.input {
    width: 43.7rem;
    outline: none;
    height: 4.8rem;
    border-radius: 10px;
    border: 1px solid #C7C8C9;
    background: #FFF;
    margin: 2rem 0.5rem 0 0;
    padding-left: 2rem;
    @media(max-width: 769px){
        width: 32.5rem;
        height: 4rem;
        margin: 1rem 0;
    }
}
.input-2 {
    height: 12.8rem;
    width: 43.7rem;
    font-size: 1.4rem;
    padding-top: 2rem;
    outline: none;
    border-radius: 10px;
    border: 1px solid #C7C8C9;
    background: #FFF;
    padding-left: 2rem;
    margin-top: 2rem;
    @media(max-width: 769px){
        width: 32.5rem;
        height: 9.6rem;
    }
}
.git-img-3 {
    align-self: flex-start;
    z-index: 100;
    margin-top: -5rem;
    @media(max-width: 769px){
        width: 10rem;
        height: 10rem;
    }
}
.git-btn {
    width: 43.7rem;
    height: 5.6rem;
    border: none;
    color: #FFF;
    background-color: #00A99D;
    border-radius: 10rem;
    margin: 2rem 0;
    cursor: pointer;
    @media(max-width: 769px){
        width: 32.5rem;
        height: 4.8rem;
    }
}
.git-form-text {
    font-size: 1.4rem;
    font-weight: 400;
    color: #000;
    width: 90%;
    @media(max-width: 769px){
        font-size: 1.2rem;
    }

}
.git-div-2 {
    margin:-8rem 0 0 8rem;
    @media(max-width: 769px){
        margin: 0;
    }
}
.git-div-2-header {
    font-family: 'Inter', sans-serif;
    font-size: 3.2rem;
    @media(max-width: 769px){
        font-size: 2rem;
        text-align: center;
    }
}
.git-div-2-text {
    font-size: 1.8rem;
    font-weight: 400;
    margin: 5rem 0;
    @media(max-width: 769px){
        text-align: center;
    }
   
}
.git-img-2 {
    display: block;
    @media(max-width: 769px){
        display: none;
    }
}
.section-hidden {
    opacity: 0;
    transform: translateY(8rem);
}