.atp{
    position: relative;
    height: 12.8rem;
    /* width: 100%; */
    background: url(./—Pngtree—pack\ of\ cute\ doodles_5571454\ 1.png),#00A99D no-repeat;
    background-size: cover;
    margin-top: 7rem;
    transition: all 2s;
    @media(max-width: 769px){
        height: 7.2rem;

    }
}
.atp-2 {
    position: relative;
    height: 12.8rem;
    /* width: 100%; */
    background: url(./—Pngtree—pack\ of\ cute\ doodles_5571454\ 1.png),#FFB347 no-repeat;
    background-size: cover;
    margin-top: 7rem;
    transition: all 2s;
    @media(max-width: 769px){
        height: 7.2rem;

    }

}
.atp-header {
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    top: 25%;
    right: 50%;
    transform: translate(50%, 50%);
    @media(max-width: 769px){
        font-size: 2rem;

    }
}
.section-hidden {
    opacity: 0;
    transform: translateY(8rem);
}
