.mission {
    display: flex;
    justify-content: space-between;
    font-family: 'Switzer Variable', sans-serif;
    width: 100%;
    padding: 2rem 6rem;
    align-items: center;
    transition: all 2s;
    @media(max-width: 769px){
        flex-direction: column;
        }

}
.mission-1 {
    width: 50%;
    @media(max-width: 769px){
        width: 100%;
        }
}
.mission-2 {
    max-width: 40%;
    @media(max-width: 769px){
        max-width: 100%;
        width: 100%;
        }
}
.mission-img{
    width: 100%;
}
.mission-header {
    color: #FFB347;
    text-align: center;
    
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2rem;
    @media(max-width: 769px){
        font-size: 2.4rem;
        }
}
.mission-1-text {
    color: #000;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: center;
    margin-bottom: 3rem;
    @media(max-width: 769px){
        font-size: 1.6rem;
        }
}
.mission-span {
    font-weight: 700;
}
.section-hidden {
    opacity: 0;
    transform: translateY(8rem);
}