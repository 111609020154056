* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
}
body {
  font-family: 'Switzer Variable', sans-serif;
  font-weight: 500;
  background: #eee;
}

