.bdt-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 8rem;
    position: fixed;
    top: 0;
    z-index: 500;
    padding: 0 6rem;
    font-family: 'Porter Sans Block', serif;
    background-color: #fff;
    backdrop-filter: blur(8px);

        @media(max-width:767px){
            padding: 0 2rem;

            
            }
            @media(min-width: 768px) and (max-width:991px){
                padding: 0 5rem;
                
                
                }
}
.bdt-header-head {
    color: #007F76;
    font-size: 2.409rem;
    font-weight: 700;
    line-height: 6.866rem;
    @media(max-width: 576px){
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 400;
        }
        @media(min-width: 577px) and (max-width:767px){
            font-size: 1.7rem;
            line-height: 4rem;
    }
    @media(min-width: 768px) and (max-width:991px){
            font-size: 2.2rem;
            line-height: 5rem;    
    }
}
.bdt-list {
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    @media(min-width: 577px) and (max-width:767px){
        display: none;
       
}
@media(min-width: 768px) and (max-width:991px){
        font-size: 1.6rem;
           
}
    @media(max-width: 576px){
        display: none;
}
}
.bdt-item:hover{
    border-bottom: #007F76 solid 3px;
    outline-offset: 3px;
}
.bdt-item {
    margin-right: 2rem;
    @media(min-width: 768px) and (max-width:991px){
        margin-right: 1.2rem;
           
    }
}
.bdt-item-1{
    padding: 1rem 4rem;
    border-radius: 3px;
    background-color: #00A99D;
    border-radius: 8px;

    @media(max-width: 576px){
        padding: none;
}
    
}
.header-link {
    text-decoration: none;
    color: #171717;
}
.header-link-1 {
    text-decoration: none;
    color: #FFFFFF;
}
.color {
    color: #007F76;
}
.open {
    display: none;
    @media(min-width: 577px) and (max-width:767px){
        display: block;
        font-size: 4rem;
        cursor: pointer;
        color: #007F76;
       
}
    @media(max-width: 576px){
    display: block;
    color: #007F76;
    font-size: 3.5rem;
    cursor: pointer;
    }
}
.close {
    color: #007F76;
    font-size: 4rem;
    cursor: pointer;
    align-self: flex-end;
}
.ham-div {
    padding: 24rem 0 0 0;
    width:100%;
    max-width: 100vw;
    /* height: 54rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    
}
.header-lists{
    color: #007F76;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
}
.header-items {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    line-height: 2.7rem;
}
.none {
    display: none;
}